import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { StructureType } from 'addiction-components';
import { EMPTY, catchError, filter, of, switchMap } from 'rxjs';
import { combineLatestWith, debounce, distinctUntilChanged, first, map, tap } from 'rxjs/operators';
import { CommunicationActions, CommunitySelectActions } from 'src/app/core/state/app.actions';
import { STATE_STATUS } from 'src/app/shared/models';
import { selectLastCommunitySelectedForApiRest } from '../../../shared/state/community-select/community-select.selectors';
import { CommunicationService } from '../services/communication.service';
import {
	setCommunicationsSuccess,
	setDeleteCommunicationsError,
	setDeleteCommunicationsSuccess,
	setDisableCommunicationsError,
	setDisableCommunicationsSuccess,
} from './communication.actions';
import {
	selectCachedPages,
	selectCommunicationsFilters,
	selectGridSearch,
	selectPagedData,
	selectPages,
	selectSort,
	selectStatus,
} from './communication.selectors';
import { Router } from '@angular/router';

@Injectable()
export class CommunicationEffect {
	private store = inject(Store);
	private actions$ = inject(Actions);
	private communicationService = inject(CommunicationService);
	private translateService = inject(TranslateService);
	private activeRoute = inject(Router);

	fetchProducts$ = createEffect(() =>
		this.actions$.pipe(
			//quando selezioni una categoria o fai una ricerca, viene triggherato il DamFilter, quindi ci registriamo ad
			//un suo eventuale successo per richiamare gli assets
			ofType(CommunicationActions.setPages, CommunicationActions.setForceRefreshCommunications),
			concatLatestFrom(() => [
				this.store.select(selectPages),
				this.store.select(selectSort),
				this.store.select(selectGridSearch),
				this.store.select(selectCommunicationsFilters),
				this.store.select(selectLastCommunitySelectedForApiRest),
				this.store.select(selectCachedPages),
			]),
			debounce(() => this.store.select(selectStatus).pipe(filter((stat) => !stat || stat !== 'loading'))),
			//non voglio caricare se non ho le pagine pronte
			filter(([, pages]) => !!pages?.length),
			tap(() => {
				this.store.dispatch(CommunicationActions.setStatus({ status: STATE_STATUS.LOADING }));
			}),
			switchMap(([, pages, sort, gridSearch, filters, communityUUID, cachedPages]) => {
				const actualPages = pages.filter((p) => !cachedPages.includes(p));
				if (!actualPages.length) return EMPTY;

				const filterWithCommunity = structuredClone(filters);
				if (communityUUID) filterWithCommunity.communityUUID = communityUUID;
				return this.communicationService
					.fetchCommunications(actualPages, sort, this.translateService.currentLang, gridSearch, filterWithCommunity)
					.pipe(
						combineLatestWith(this.store.select(selectPagedData)),
						first(),
						map(([data, startingResult]) => {
							// console.log(startingResult);
							startingResult.pages = cachedPages;
							const result = data.reduce((acc, item) => {
								if (item.paginationInfo) {
									acc.pages.push(item.paginationInfo.pageNumber);
									acc.totalCommunicationsCount = item.paginationInfo.totalElements ?? 0;
									if (item.result) {
										acc.communications[item.paginationInfo.pageNumber] = item.result.map((communication) => ({
											...communication,
											features: communication.featureValueList ? communication.featureValueList.map((f) => f.name || f.label) : [],
										}));
									}
								}
								return acc;
							}, structuredClone(startingResult));

							// console.log(result);

							return CommunicationActions.setCommunicationsSuccess({ data: result });
						}),
						catchError((error: HttpErrorResponse) => {
							console.error(error);
							return of(CommunicationActions.setCommunicationsError({ error }));
						})
					);
			})
		)
	);

	deleteCommunication$ = createEffect(() =>
		this.actions$.pipe(
			ofType(CommunicationActions.deleteCommunications),
			// invoke API
			switchMap(({ uuids }) => {
				return this.communicationService.deleteCommunication(uuids, StructureType.COMMUNICATION).pipe(
					map(() => {
						this.communicationService.getStructureAndFeatures(StructureType.COMMUNICATION);
						return CommunicationActions.setDeleteCommunicationsSuccess();
					}),
					catchError((error: HttpErrorResponse) => of(CommunicationActions.error({ error: { errorType: 'generic_http', data: error } })))
				);
			})
		)
	);

	resetCache$ = createEffect(() =>
		this.actions$.pipe(
			ofType(
				CommunicationActions.setGridSearch,
				CommunicationActions.setSort,
				CommunicationActions.updateFilters,
				CommunicationActions.addFeatureValueToFilters,
				CommunicationActions.removeFeatureValueFromFilters
			),
			concatLatestFrom(() => [
				this.store.select(selectPages),
				this.store.select(selectSort),
				this.store.select(selectGridSearch),
				this.store.select(selectCommunicationsFilters),
				this.store.select(selectCachedPages),
			]),
			// //non voglio caricare se i dati delle azioni sono gli stessi
			distinctUntilChanged(
				(
					[, pagesPrev, sortPrev, gridSearchPrev, filterPrev],
					[, pagesCurr, sortCurr, gridSearchCurr, filterCurr, cachedPages]
				) => {
					// console.log(action);
					return (
						pagesPrev.length === pagesCurr.length &&
						pagesPrev.every((f) => pagesCurr.includes(f)) &&
						sortCurr?.active === sortPrev?.active &&
						sortCurr?.direction === sortPrev?.direction &&
						filterPrev === filterCurr &&
						gridSearchCurr === gridSearchPrev &&
						pagesPrev.filter((f) => !cachedPages.includes(f)).length === 0
					);
				}
			),
			map(() => CommunicationActions.resetCache())
		)
	);

	disableCommunications$ = createEffect(() =>
		this.actions$.pipe(
			ofType(CommunicationActions.disableCommunications),
			// invoke API
			switchMap(({ uuids }) =>
				this.communicationService.multipleUpdate({
					uuidList: uuids,
					active: false,
				})
			),
			// must re-fetch to repaginate
			map(() => {
				return CommunicationActions.setDisableCommunicationsSuccess();
			}),
			// error handling
			catchError((error: unknown) => of(CommunicationActions.error({ error: { errorType: 'generic_http', data: error } }))),
			tap(() => this.communicationService.getStructureAndFeatures(StructureType.COMMUNICATION))
		)
	);

	deleteAndDisableCommunication$ = createEffect(() =>
		this.actions$.pipe(
			ofType(setDeleteCommunicationsSuccess, setDeleteCommunicationsError, setDisableCommunicationsSuccess, setDisableCommunicationsError),
			map(() => {
				return CommunicationActions.resetCache();
			})
		)
	);

	communicationSuccess$ = createEffect(() =>
		this.actions$.pipe(
			ofType(setCommunicationsSuccess),
			map(({ data: { pages } }) => CommunicationActions.setCachedPages({ pages }))
		)
	);

	forceRefreshTable$ = createEffect(() =>
		this.actions$.pipe(
			ofType(CommunicationActions.resetCache),
			map(() => CommunicationActions.setForceRefreshCommunications())
		)
	);

    refreshCommunity$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CommunitySelectActions.setLastCommunitySelected),
            filter(() => this.activeRoute.url.length > 0 && this.activeRoute.url.includes('communications')),
            map(() => CommunicationActions.resetCache())
        )
    );

	constructor() {}
}
