import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EMPTY, catchError, combineLatestWith, debounce, distinctUntilChanged, filter, first, map, of, switchMap, tap } from 'rxjs';
import { CommunitySelectSelectors, GroupsSelectors, PracticeSectionsSelector } from 'src/app/core/state/app.selectors';
import { STATE_STATUS } from 'src/app/shared/models/state-status.enum';
import {
	setDeletePracticeSection,
	setDeletePracticeSectionError,
	setDeletePracticeSectionSuccess,
	setSectionsError,
	setSectionsSuccess,
	setStatus,
} from './practice-section.actions';
import { selectGridSearch, selectPagedData, selectPages, selectSort, selectStatus } from './practice-section.selectors';
import { CommunitySelectActions, PracticeSectionsActions } from 'src/app/core/state/app.actions';
import { InformedConsentService } from '../../informed-consent.service';
import { Router } from '@angular/router';

@Injectable()
export class PracticeSectionEffects {
	private actions$ = inject(Actions);
	private store = inject(Store);
	private informedConsentService = inject(InformedConsentService);
	private activeRoute = inject(Router);

	constructor() {}

	fetchGroups$ = createEffect(() =>
		this.actions$.pipe(
			//quando selezioni una categoria o fai una ricerca, viene triggherato il DamFilter, quindi ci registriamo ad
			//un suo eventuale successo per richiamare gli assets
			ofType(PracticeSectionsActions.setPages, PracticeSectionsActions.setForceRefreshPracticeSections),
			concatLatestFrom(() => [
				this.store.select(PracticeSectionsSelector.selectPages),
				this.store.select(PracticeSectionsSelector.selectSort),
				this.store.select(PracticeSectionsSelector.selectGridSearch),
				this.store.select(PracticeSectionsSelector.selectCachedPages),
			]),
			debounce(() => {
				return this.store.select(selectStatus).pipe(
					filter((stat) => {
						return !stat || stat !== 'loading';
					})
				)
      }),
			//non voglio caricare se non ho le pagine pronte
			filter(([, pages]) => {
        return !!pages?.length
      }),
			tap(() => {
				this.store.dispatch(setStatus({ status: STATE_STATUS.LOADING }));
			}),
			switchMap(([, pages, sort, gridSearch, cachedPages]) => {
				const actualPages = pages.filter((p) => !cachedPages.includes(p));
				if (!actualPages.length) return EMPTY;

				return this.informedConsentService.fetchSections(actualPages, sort, gridSearch).pipe(
					combineLatestWith(this.store.select(selectPagedData)),
					first(),
					map(([data, startingResult]) => {
						startingResult.pages = cachedPages;
						const result = data.reduce((acc, item) => {
							if (item.paginationInfo) {
								acc.pages.push(item.paginationInfo.numberOfPage);
								acc.totalSectionsCount = item.paginationInfo.totalNumberOfElements;
								if (item.result) {
									acc.sections[item.paginationInfo.numberOfPage] = item.result;
								}
							}
							return acc;
						}, structuredClone(startingResult));

						return setSectionsSuccess({ data: result });
					}),
					catchError((error: HttpErrorResponse) => {
						return of(setSectionsError({ error }));
					})
				);
			})
		)
	);

	resetCache$ = createEffect(() =>
		this.actions$.pipe(
			ofType(PracticeSectionsActions.setGridSearch, PracticeSectionsActions.setSort),
			concatLatestFrom(() => [
				this.store.select(selectPages),
				this.store.select(selectSort),
				this.store.select(selectGridSearch),
				this.store.select(GroupsSelectors.selectCachedPages),
			]),
			//non voglio caricare se i dati delle azioni sono gli stessi
			distinctUntilChanged(
				([, pagesPrev, sortPrev, gridSearchPrev, ], [, pagesCurr, sortCurr, gridSearchCurr, cachedPages]) => {
					return (
						pagesPrev.length === pagesCurr.length &&
						pagesPrev.every((f) => pagesCurr.includes(f)) &&
						sortCurr?.active === sortPrev?.active &&
						sortCurr?.direction === sortPrev?.direction &&
						gridSearchCurr === gridSearchPrev &&
						pagesPrev.filter((f) => !cachedPages.includes(f)).length === 0
					);
				}
			),
			map(() => PracticeSectionsActions.resetCache())
		)
	);

	deletePracticeSection$ = createEffect(() =>
		this.actions$.pipe(
			ofType(setDeletePracticeSection),
			switchMap(({ section }) => {
				return this.informedConsentService.archiveSection(section).pipe(
					map(() => {
						return setDeletePracticeSectionSuccess();
					}),
					catchError((error: HttpErrorResponse) => {
						return of(setSectionsError({ error }));
					})
				);
			})
		)
	);

	deletePracticeSections$ = createEffect(() =>
		this.actions$.pipe(
			ofType(setDeletePracticeSectionSuccess, setDeletePracticeSectionError),
			map(() => PracticeSectionsActions.resetCache())
		)
	);

	practiceSectionSuccess$ = createEffect(() =>
		this.actions$.pipe(
			ofType(setSectionsSuccess),
			map(({ data: { pages } }) => PracticeSectionsActions.setCachedPages({ pages }))
		)
	);

	forceRefreshTable$ = createEffect(() =>
		this.actions$.pipe(
			ofType(PracticeSectionsActions.resetCache),
			map(() => PracticeSectionsActions.setForceRefreshPracticeSections())
		)
	);

	refreshCommunity$ = createEffect(() =>
		this.actions$.pipe(
			ofType(CommunitySelectActions.setLastCommunitySelected),
			filter(() => this.activeRoute.url.length > 0 && this.activeRoute.url.includes('/informed-consent')),
			map(() => PracticeSectionsActions.resetCache())
		)
	);
}
